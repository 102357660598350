var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('fw-title',{staticStyle:{"padding-top":"25px"},attrs:{"titles":"收货明细"}}),(_vm.type == 1)?_c('el-row',[_c('el-col',{attrs:{"span":24,"align":"left"}})],1):_vm._e(),_c('div',{staticStyle:{"margin":"15px 0"}},[_c('vxe-table',{ref:"xTable",staticClass:"mytable-scrollbar",staticStyle:{"margin-top":"10px","min-height":"10vh"},attrs:{"highlight-hover-row":"","auto-resize":"","stripe":"","size":"small","align":"left","data":_vm.tableList}},[_c('vxe-column',{attrs:{"type":"checkbox","width":"40","fixed":"left"}}),_c('vxe-column',{attrs:{"type":"seq","title":"序号","width":"50","fixed":"left"}}),_c('vxe-column',{attrs:{"min-width":"160","fixed":"left"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("供应商料号-小号")]),_c('p',[_vm._v("商品名称")]),_c('p',[_vm._v("商品单位")]),_c('p',[_vm._v("商品描述")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":((row.order_com_sku.com_sku.serial_number ||
                '--') + " - " + ((row.order_com_sku.com_sku.com_sku_goods &&
                row.order_com_sku.com_sku.com_sku_goods.goods_small) ||
                ''))}},[_vm._v(" "+_vm._s(((row.order_com_sku.com_sku.serial_number || "--") + " - " + ((row.order_com_sku.com_sku.com_sku_goods && row.order_com_sku.com_sku.com_sku_goods.goods_small) || "")))+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.order_com_sku.com_sku.name}},[_vm._v(" "+_vm._s(row.order_com_sku.com_sku.name || "--")+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.order_com_sku.com_sku.com_sku_goods &&
                row.order_com_sku.com_sku.com_sku_goods.goods_unit_name}},[_vm._v(" "+_vm._s((row.order_com_sku.com_sku.com_sku_goods && row.order_com_sku.com_sku.com_sku_goods.goods_unit_name) || "--")+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.order_com_sku.com_sku.info}},[_vm._v(" "+_vm._s(row.order_com_sku.com_sku.info || "--")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("供给方统一社会信用代码")]),_c('p',[_vm._v("供给方名称")])]},proxy:true},{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.order_com_sku.seller_company_code}},[_vm._v(" "+_vm._s(row.order_com_sku.seller_company_code || "--")+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.order_com_sku.seller_company_name}},[_vm._v(" "+_vm._s(row.order_com_sku.seller_company_name || "--")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("收货类型")]),_c('p',[_vm._v("来源单号")]),_c('p',[_vm._v("行号")])]},proxy:true},{key:"default",fn:function(ref){
                var row = ref.row;
                var $rowIndex = ref.$rowIndex;
return [_c('p',[_vm._v(" 商城订单 ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.serial_number}},[_vm._v(" "+_vm._s(row.serial_number || "--")+" ")]),_c('p',{staticClass:"ellipsisText"},[_vm._v(" "+_vm._s($rowIndex + 1)+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("订单数量")]),_c('p',[_vm._v("订单单位")]),_c('p',[_vm._v("订单金额")])]},proxy:true},{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.order_com_sku.buy_count}},[_vm._v(" "+_vm._s(row.order_com_sku.buy_count || "--")+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.order_com_sku.com_sku_valuation_unit}},[_vm._v(" "+_vm._s(row.order_com_sku.com_sku_valuation_unit || "--")+" ")]),_c('p',{staticClass:"ellipsisText",staticStyle:{"color":"#F74848"}},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(row.order_com_sku.protocol_sku_subtotal == "0.00" ? row.order_com_sku.com_sku_subtotal : row.order_com_sku.protocol_sku_subtotal))+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("发货方")]),_c('p',[_vm._v("发货时间")]),_c('p',[_vm._v("物流单号")])]},proxy:true},{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.send_name}},[_vm._v(" "+_vm._s(row.send_name || "--")+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.send_at}},[_vm._v(" "+_vm._s(row.send_at || "--")+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.logistics_serial_number}},[_vm._v(" "+_vm._s(row.logistics_serial_number || "--")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("来源-仓储批")]),_c('p',[_vm._v("来源-装箱号")]),_c('p',[_vm._v("来源-包装标识")])]},proxy:true},{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.storage_batch_no}},[_vm._v(" "+_vm._s(row.storage_batch_no || "--")+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.box_code}},[_vm._v(" "+_vm._s(row.box_code || "--")+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.pack_code}},[_vm._v(" "+_vm._s(row.pack_code || "--")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("来源-数量")]),_c('p',[_vm._v("来源-单位")]),_c('p',[_vm._v("发货金额")])]},proxy:true},{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.quantity}},[_vm._v(" "+_vm._s(row.quantity || 0 || "--")+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.unit_name}},[_vm._v(" "+_vm._s(row.unit_name || "--")+" ")]),_c('h4',{staticClass:"ellipsisText",staticStyle:{"color":"#F74848"},attrs:{"title":row.amount}},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(row.amount))+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("来源-批次号")]),_c('p',[_vm._v("来源-序列号")]),_c('p',[_vm._v("来源-有效期")])]},proxy:true},{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.goods_batch_no}},[_vm._v(" "+_vm._s(row.goods_batch_no || "--")+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.goods_sn}},[_vm._v(" "+_vm._s(row.goods_sn || "--")+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.goods_expire}},[_vm._v(" "+_vm._s(row.goods_expire || "--")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("收货-数量")]),_c('p',[_vm._v("收货-单位")]),_c('p',[_vm._v("收货金额")])]},proxy:true},{key:"default",fn:function(ref){
                var row = ref.row;
return [(row.is_input)?[_c('div',{staticStyle:{"margin-bottom":"10px"}},[_c('el-input-number',{staticStyle:{"width":"140px"},attrs:{"controls-position":"right","precision":3,"step":0.001,"min":0,"max":Number(row.quantity)},model:{value:(row.receipt_quantity),callback:function ($$v) {_vm.$set(row, "receipt_quantity", $$v)},expression:"row.receipt_quantity"}})],1),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.receipt_unit_name}},[_vm._v(" "+_vm._s(row.receipt_unit_name || "--")+" ")]),_c('h4',{staticClass:"ellipsisText",staticStyle:{"color":"#F74848"}},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(_vm.subtotal(row)))+" ")])]:[_c('p',{staticClass:"ellipsisText",attrs:{"title":row.receipt_quantity}},[_vm._v(" "+_vm._s(row.receipt_quantity || "--")+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.receipt_unit_name}},[_vm._v(" "+_vm._s(row.receipt_unit_name || "--")+" ")]),_c('h4',{staticClass:"ellipsisText",staticStyle:{"color":"#F74848"}},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(_vm.subtotal(row)))+" ")])]]}}])}),_c('vxe-column',{attrs:{"fixed":"right","min-width":"100"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("操作")])]},proxy:true},{key:"default",fn:function(ref){
                var row = ref.row;
return [(_vm.type == 1)?[_c('p',{staticClass:"color",on:{"click":function($event){return _vm.upFile(row)}}},[_vm._v("上传附件")]),(row.is_input)?[_c('span',{staticClass:"color",staticStyle:{"margin-right":"10px"},on:{"click":function($event){return _vm.confirm(row)}}},[_vm._v("确认")]),_c('span',{staticClass:"color",on:{"click":function($event){return _vm.cancel(row)}}},[_vm._v("取消")])]:[_c('p',{staticClass:"color",on:{"click":function($event){return _vm.editBtn(row)}}},[_vm._v("收货")])]]:[_c('p',{staticClass:"color",on:{"click":function($event){return _vm.lookFile(row)}}},[_vm._v("查看附件")])]]}}])})],1)],1),_c('choseStock',{ref:"choseStock"}),_c('fileDialog',{ref:"fileDialog"}),_c('lookfile',{ref:"lookfile"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }