<template>
  <div class="wra">
    <el-drawer
      :visible.sync="visible"
      :wrapperClosable="false"
      :with-header="false"
      direction="rtl"
      size="80%"
      id="addSucai"
      :destroy-on-close="true"
    >
      <el-container style="height: 100%">
        <el-header height="auto">
          <fw-header titles="发货" />
        </el-header>
        <template v-if="!is_address">
          <el-alert title="请联系采购方确认配送地址后再发货" type="warning" show-icon></el-alert>
        </template>
        <template v-else>
          <el-main v-if="dataInfo">
            <basmsg :dataInfo="dataInfo" />
            <div class="main_bot">
              <div>
                <span style="margin-right:45px;color:#858585;">配送至</span>
                <span style="margin-right:15px">
                  {{ dataInfo.receipt_info.name || "--" }}
                </span>
                <span style="margin-right:15px">
                  {{ dataInfo.receipt_info.mobile }}
                </span>
                <span style="margin-right:15px">
                  {{ dataInfo.receipt_info.province_name }}&nbsp;{{
                    dataInfo.receipt_info.city_name
                  }}&nbsp;{{ dataInfo.receipt_info.area_name }}&nbsp;{{
                    dataInfo.receipt_info.address
                  }}
                </span>
              </div>
            </div>
            <fw-title titles="发货人信息" style="padding-top: 25px" />
            <el-row style="padding: 10px 15px">
              <el-form
                ref="ruleForm1"
                :rules="rules"
                label-position="left"
                label-width="125px"
                :model="formLabelAlign"
                size="small"
              >
                <el-form-item label="发货方名称" prop="send_name">
                  <el-input
                    type="text"
                    placeholder="请输入"
                    v-model="formLabelAlign.send_name"
                    style="width: 400px;margin-right:15px"
                  ></el-input>
                  <i class="fa fa-pencil-square-o empha" @click="choseComp"></i>
                </el-form-item>
                <el-form-item label="发货人" prop="send_by">
                  <el-input
                    type="text"
                    placeholder="请输入"
                    v-model="formLabelAlign.send_by"
                    maxlength="32"
                    show-word-limit
                    style="width: 400px;margin-right:15px"
                  ></el-input>
                  <i class="fa fa-pencil-square-o empha" @click="choseStaff"></i>
                </el-form-item>
                <el-form-item label="联系方式" prop="send_mobile">
                  <el-input
                    type="text"
                    placeholder="请输入"
                    v-model="formLabelAlign.send_mobile"
                    maxlength="32"
                    show-word-limit
                    style="width: 400px;"
                  ></el-input>
                </el-form-item>
                <el-form-item label="发货地址" prop="send_address">
                  <div>
                    <template v-if="formLabelAlign.send_address">
                      <div @click="openAddChose" class="empha">
                        {{ formLabelAlign.send_address }}
                      </div>
                    </template>
                    <template v-else>
                      <span @click="openAddChose" class="empha">
                        选择发货地址
                      </span>
                    </template>
                  </div>
                  <div style="margin-top:12px">
                    <el-input
                      :autosize="{ minRows: 5, maxRows: 7 }"
                      resize="none"
                      placeholder="请输入"
                      style="width: 400px"
                      type="textarea"
                      v-model="formLabelAlign.send_address"
                      maxlength="256"
                      show-word-limit
                    ></el-input>
                  </div>
                </el-form-item>
              </el-form>
            </el-row>
            <div>
              <shipments-table :tableList="dataInfo.order_com_sku" />
            </div>
            <div>
              <fw-title titles="发运包装" style="padding-top: 25px" />
              <el-row class="packzone">
                <el-col :span="2" style="color:#606266">
                  <span class="konggefu"></span>
                  发运包装
                </el-col>
                <el-col :span="22">
                  <pack ref="pack" @setPack="setPack" :listData="pack"></pack>
                </el-col>
                <el-col :span="2" style="color:#606266">
                  <span class="konggefu"></span>
                  发货图片
                </el-col>

                <el-col :span="22">
                  <div style="padding: 0px 15px;display:flex">
                    <fwuploadphoto
                      v-if="photoflag"
                      @postImgMsg="postImgMsg"
                      :backShow="send_image"
                      :mustDelet="true"
                      :onlyLook="true"
                    />
                    <fwmobileupload suffix="photo" @succ="succ" />
                  </div>
                </el-col>
                <el-col :span="2" style="color:#606266">
                  <span class="konggefu"></span>
                  发运包装
                </el-col>
                <el-col :span="22">
                  <div style="padding: 0px 15px">
                    <el-input
                      :autosize="{ minRows: 5, maxRows: 7 }"
                      resize="none"
                      placeholder="请输入"
                      style="width: 400px"
                      maxlength="100"
                      show-word-limit
                      type="textarea"
                      v-model="send_desc"
                    ></el-input>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div>
              <fw-title titles="发运信息" style="padding-top: 15px" />
              <el-row style="padding: 5px 15px">
                <el-form
                  ref="ruleForm4"
                  :rules="rules"
                  label-position="left"
                  label-width="125px"
                  :model="formLabelAlign"
                  size="small"
                >
                  <el-form-item>
                    <div slot="label">
                      <span class="konggefu"></span>
                      运输方式
                    </div>
                    <el-radio-group v-model="formLabelAlign.shipping_model">
                      <el-radio :label="1">公路运输</el-radio>
                      <el-radio :label="2">铁路运输</el-radio>
                      <el-radio :label="3">水路运输</el-radio>
                      <el-radio :label="4">航空运输</el-radio>
                      <el-radio :label="5">管道运输</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item>
                    <div slot="label">
                      <span class="konggefu"></span>
                      运费承担
                    </div>
                    <el-radio-group v-model="formLabelAlign.freight">
                      <el-radio :label="1">发货方</el-radio>
                      <el-radio :label="2">收货方</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item>
                    <div slot="label">
                      <span class="konggefu"></span>
                      卸货方
                    </div>
                    <el-radio-group v-model="formLabelAlign.unload">
                      <el-radio :label="1">发货方</el-radio>
                      <el-radio :label="2">收货方</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-form>
              </el-row>
            </div>
            <div>
              <fw-title titles="承运方信息" style="padding-top: 25px" />
              <el-row class="packzone">
                <el-col :span="2" style="color:#606266">
                  <span class="konggefu"></span>
                  承运方
                </el-col>
                <el-col :span="22">
                  <div v-if="transport" class="choseGongji">
                    <p
                      class="empha ellipsisText"
                      @click="choseDutyBtn"
                      :title="`${transport.name} / ${transport.serial_number}`"
                    >
                      {{ `${transport.name} / ${transport.serial_number}` }}
                    </p>
                    <i class="el-icon-error empha" @click="deltDuty"></i>
                  </div>
                  <div v-else>
                    <el-button plain class="btn" @click="choseDutyBtn" icon="el-icon-circle-plus">
                      选择承运方
                    </el-button>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div>
              <fw-title titles="物流信息" style="padding-top: 25px" />
              <el-row style="padding: 5px 15px">
                <el-form
                  ref="ruleForm4"
                  :rules="rules"
                  label-position="left"
                  label-width="125px"
                  :model="formLabelAlign"
                  size="small"
                >
                  <el-form-item>
                    <div slot="label">
                      <span class="konggefu"></span>
                      物流单号
                    </div>
                    <div class="logistics_bar">
                      <el-input
                        v-model="formLabelAlign.logistics_serial_number"
                        maxlength="32"
                        show-word-limit
                        style="width: 400px;margin-right:15px"
                        placeholder="请输入"
                      ></el-input>

                      <el-input
                        v-model="formLabelAlign.logistics_company"
                        style="width: 400px;"
                        placeholder="请输入物流公司"
                        prefix-icon="el-icon-search"
                      ></el-input>
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <div slot="label">
                      <span class="konggefu"></span>
                      到货时间
                    </div>
                    <el-date-picker
                      v-model.trim="formLabelAlign.arrival_at"
                      type="datetime"
                      placeholder="选择日期时间"
                      style="width: 400px"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                    ></el-date-picker>
                  </el-form-item>
                  <el-form-item>
                    <div slot="label">
                      <span class="konggefu"></span>
                      承运图片
                    </div>
                    <div style="display:flex">
                      <fwuploadphoto
                        v-if="transportflag"
                        @postImgMsg="postImgMsg2"
                        :backShow="formLabelAlign.transport_file"
                        :mustDelet="true"
                        :onlyLook="true"
                      />
                      <fwmobileupload suffix="photo" @succ="succ2" />
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <div slot="label">
                      <span class="konggefu"></span>
                      承运备注
                    </div>
                    <div style="display:flex">
                      <el-input
                        :autosize="{ minRows: 5, maxRows: 7 }"
                        resize="none"
                        placeholder="请输入"
                        style="width: 400px"
                        maxlength="100"
                        show-word-limit
                        type="textarea"
                        v-model="formLabelAlign.transport_desc"
                      ></el-input>
                    </div>
                  </el-form-item>
                </el-form>
              </el-row>
            </div>
          </el-main>
        </template>
        <el-footer>
          <el-row>
            <el-col :span="24" align="center">
              <el-button size="small" @click="close">取消</el-button>
              <el-button size="small" type="primary" @click="confirmBtn" v-if="is_address">
                提交
              </el-button>
            </el-col>
          </el-row>
        </el-footer>
      </el-container>
      <fw-inoutsup ref="fwInoutsup" />
      <fw-chosePeople ref="fw_chosePeople" />
    </el-drawer>
  </div>
</template>

<script>
import Basmsg from "./basmsg.vue";
import pack from "./pack.vue";
import ShipmentsTable from "./shipmentsTable.vue";
export default {
  name: "",
  components: {
    Basmsg,
    ShipmentsTable,
    pack,
  },
  props: {},
  created() {},
  mounted() {},
  data() {
    return {
      visible: false,
      callFn: null, //回调
      infoId: null,
      dataInfo: null,
      formLabelAlign: {},
      rules: {
        send_name: [{ required: true, message: "请选择发货方名称", trigger: "blur" }],
        send_by: [{ required: true, message: "请选择发货人", trigger: "blur" }],
        send_mobile: [{ required: true, message: "请输入联系方式", trigger: "blur" }],
        send_address: [{ required: true, message: "请选择发货地址", trigger: "change" }],
      },
      pack: [], //发运包装
      send_image: [], //发货图片
      photoflag: true,
      send_desc: "", //发货备注
      transport: null, //承运方信息

      transportflag: true,
      is_address: true, //是否有配送地址
    };
  },
  watch: {},
  computed: {},
  methods: {
    open(data, callFn) {
      console.log(data);
      this.dataInfo = null;
      this.callFn = callFn;
      this.infoId = data.id;
      this.getDefault();
      this.getBasicInfo();
      this.visible = true;
    },
    getDefault() {
      this.formLabelAlign = {
        send_name: "", //发货方名称
        send_by: "", //发货人
        send_staff_id: 0, //发货人员工id
        send_mobile: "", //联系电话
        send_address: "", //发货地址
        shipping_type: 2, //发运类型1虚拟2实物
        shipping_model: 1, //运输方式1公路运输2铁路运输3水路运输4航空运输5管道运输
        freight: 1, //运费承担1发货方2收货方
        unload: 1, //卸车方1发货方2收货方
        logistics_serial_number: "", //物流单号
        logistics_company: "", //物流公司
        arrival_at: "", //到货时间
        transport_file: [], //承运图片
      };
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      console.log(userInfo);
      this.formLabelAlign.send_name = userInfo.staff_main_department.name;
      this.formLabelAlign.send_by = userInfo.staff.name;
      this.formLabelAlign.send_staff_id = userInfo.staff.id;
      this.formLabelAlign.send_mobile = userInfo.staff.mobile;
      this.pack = [];
      this.send_image = [];
      this.send_desc = ""; //发货备注
      this.transport = null;
    },
    getBasicInfo() {
      this.$axios.get(`/shop/order/seller/detail`, { id: Number(this.infoId) }).then(res => {
        if (res.data) {
          console.log(res.data);
          res.data.order_com_sku.map(v => {
            v["send_info"] = [];
            v["num"] = "";
            v["amount"] = "";
            return v;
          });
          this.dataInfo = res.data;
          this.is_address = this.dataInfo.receipt_info ? true : false;
        }
      });
    },
    choseComp() {
      let params = {
        type: "radio",
        title: "选择发货方",
        showLook: [2],
        onlyCompany: true,
        companySearch: {},
      };
      this.$refs.fwInoutsup.open(params, val => {
        console.log(val);
        let value = val.data;
        this.formLabelAlign.send_name = value.name;
        //重置数据
      });
    },
    choseStaff() {
      this.$refs.fw_chosePeople.open(
        {
          titles: "选择发货人",
          noDepartment: true, // 不用选择部门，
          choseData: [], // 已选择的数据。
          type: "radio", // 类型为单选radio,checkbox,
          contact_type: "0", //0.只能内部，不能切换 1.内部，2.外部,3 只能外部，并不能切换
          defaultSearch: { status: [1, 2] }, // 默认参数
        },
        val => {
          console.log(val);
          let data = val.list.choseMsg;
          //回显参数
          this.formLabelAlign.send_by = data.name;
          this.formLabelAlign.send_staff_id = data.id;
          this.formLabelAlign.send_mobile = data.mobile;
        }
      );
    },
    // mapAddress(val) {
    //   console.log(val);
    //   let obj = JSON.parse(val);
    //   this.formLabelAlign.send_address = obj.province + obj.city + obj.area + obj.address;
    // },
    openAddChose() {
      this.$refs.fwchoseaddress.open({ title: "选择地址", address_type: 2 }, obj => {
        console.log(obj);
        this.formLabelAlign.send_address = obj.province + obj.city + obj.area + obj.address;
      });
    },
    setPack(val) {
      console.log(val);
      this.pack = JSON.parse(JSON.stringify(val));
    },
    succ(val) {
      console.log(val);
      this.photoflag = false;
      this.send_image = [...this.send_image, ...val];
      this.$nextTick(() => {
        this.photoflag = true;
      });
      console.log(this.send_image);
    },
    postImgMsg(val) {
      console.log(val);
    },
    postImgMsg2(val) {
      console.log(val);
    },
    choseDutyBtn() {
      let params = {
        type: "radio",
        title: "选择承运方",
        showLook: [0, 1, 2],
        onlyCompany: true,
        companySearch: {},
      };
      this.$refs.fwInoutsup.open(params, val => {
        console.log(val);
        let value = val.data;
        let type = val.type; //重置数据
        this.transport = {
          name: type == 1 ? value.supplier_name : value.name,
          serial_number: value.serial_number,
          contact_name: type == 2 ? "" : value.first_contact?.name,
          mobile: type == 2 ? "" : value.first_contact?.mobile,
          email: type == 2 ? "" : value.first_contact?.email,
        };
      });
    },
    deltDuty() {
      this.transport = null;
    },
    succ2(val) {
      console.log(val);
      this.transportflag = false;
      this.formLabelAlign.transport_file = [...this.formLabelAlign.transport_file, ...val];
      this.$nextTick(() => {
        this.transportflag = true;
      });
      console.log(this.formLabelAlign.transport_file);
    },
    confirmBtn() {
      this.$refs.ruleForm1.validate(valid => {
        if (valid) {
          let goods_list = this.dataInfo.order_com_sku
            .map(v => {
              let obj = {
                order_com_sku_id: v.id, //订单商品id
                com_sku_id: v.com_sku_id, //商品id
                num: v.num, //发货数量
                amount: v.amount, //发货金额
                send_info: v.send_info,
              };
              return obj;
            })
            .filter(obj => {
              return obj.num !== 0;
            });
          console.log(goods_list);
          let shipping_quantity = goods_list.reduce(function(total, item) {
            return total + item.num;
          }, 0);
          let shipping_amount = goods_list.reduce(function(total, item) {
            return total + item.amount;
          }, 0);
          let isAllSendInfoFalse = goods_list.every(goods => {
            return goods.send_info.every(sendInfo => {
              return sendInfo.is_input === false;
            });
          });
          console.log(isAllSendInfoFalse);
          if (!isAllSendInfoFalse) {
            return this.$message.warning("请确认发货商品的发货数量");
          }
          console.log(this.pack);
          let pack = this.pack;
          if (pack.length == 0) {
            return this.$message.warning("请选择发运包装");
          }
          let packFlag = pack.every(sendInfo => {
            return sendInfo.is_input === false;
          });
          if (!packFlag) {
            return this.$message.warning("请先确认发运包装的操作");
          }
          let receive_address = `${this.dataInfo.receipt_info.province_name}${this.dataInfo.receipt_info.city_name}${this.dataInfo.receipt_info.area_name}${this.dataInfo.receipt_info.address}`;
          let params = {
            ...this.formLabelAlign,
            goods_list: goods_list,
            order_id: Number(this.infoId),
            express: "", //快递公司
            send_image: this.send_image,
            send_desc: this.send_desc,
            transport: this.transport,
            shipping_amount: shipping_amount, //发货金额
            shipping_quantity: shipping_quantity, //发货数量
            pack: pack,
            receive_name: this.dataInfo.receipt_info.name, //收货方名称
            receive_address: receive_address, //收货地址
            receive_mobile: this.dataInfo.receipt_info.mobile, //收货方联系电话
          };
          console.log(params);
          this.$axios.putJSON("/shop/order/seller/send", params).then(res => {
            if (res.code == 200) {
              console.log(res);
              this.visible = false;
              this.callFn && this.callFn(true);
            }
          });
        }
      });
    },
    close() {
      this.visible = false;
      this.dataInfo = null;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-drawer__body {
  padding-left: 25px;
  padding-right: 25px;
}
.main_bot {
  background-color: #fafafa;
  margin-top: 25px;
  line-height: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px 25px;
  div {
    display: flex;
    align-items: center;
  }
}
.packzone {
  padding: 10px 15px;
  .el-col {
    margin-bottom: 15px;
  }
}
.choseGongji {
  margin-top: 12px;
  position: relative;
  display: inline-block;
  min-width: 100px;
  margin-right: 3%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fafafa;
  & > p {
    color: #0088fe;
    line-height: 30px !important;
  }
  p {
    line-height: 24px;
  }
  & > i {
    position: absolute;
    top: -10px;
    right: -15px;
    color: #b6b6b6;
    cursor: pointer;
  }

  & > div {
    display: flex;
    & > div:nth-child(1) {
      width: 100px;
    }
    & > div:nth-child(2) {
      overflow: hidden;
      flex: 1;
      p {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.btn {
  width: 400px;
  border-color: #409eff;
  color: #409eff;
  &:hover {
    background-color: #c0defc;
    font-weight: bolder;
  }
}
.logistics_bar {
  display: flex;
  align-items: center;
}
</style>
