<template>
  <el-row class="basmsg" v-if="dataInfo">
    <el-col :span="24" class="divs">
      <h4>{{ dataInfo.serial_number }}</h4>
    </el-col>
    <el-col :span="8" class="divs">
      付款方式：{{ dataInfo.order_model == 1 ? "先款后货" : "先货后款" }}
    </el-col>
    <el-col :span="8" class="divs">销售方：{{ dataInfo.seller_company_name }}</el-col>
    <el-col :span="8" class="divs">
      订单货款状态：
      <template v-if="dataInfo.pay_status == 0">
        <span style="background-color: #e6a23c" class="colors"></span>
        <span style="color: #e6a23c">待支付</span>
      </template>
      <template v-else-if="dataInfo.pay_status == 1">
        <span style="background-color: #409eff" class="colors"></span>
        <span style="color: #409eff">部分支付</span>
      </template>
      <template v-else-if="dataInfo.pay_status == 2">
        <span style="background-color: #7ac756" class="colors"></span>
        <span style="color: #7ac756">全部支付</span>
      </template>
      <template v-else-if="dataInfo.pay_status == 3">
        <span style="background-color: #7ac756" class="colors"></span>
        <span style="color: #7ac756">超额支付</span>
      </template>
      <template v-else>
        --
      </template>
    </el-col>
    <el-col :span="8" class="divs">下单人：{{ dataInfo.buyer_company_name }}</el-col>
    <el-col :span="8" class="divs">
      订单来源：{{
        dataInfo.source == 1 ? "自主新增" : dataInfo.source == 2 ? "企业购物车" : "商城下单"
      }}
    </el-col>
    <el-col :span="8" class="divs">
      订单发票状态：
      <span v-if="dataInfo.invoice_status == 0">
        <span style="background-color:#f8ba43" class="colors2"></span>
        <span>未开票</span>
      </span>
      <span v-else-if="dataInfo.invoice_status == 1">
        <span style="background-color:#7ac756" class="colors2"></span>
        <span>已开票</span>
      </span>
      <span v-else>--</span>
    </el-col>
    <el-col :span="8" class="divs">下单时间：{{ dataInfo.created_at }}</el-col>
    <el-col :span="8" class="divs">
      商品总额：
      <span style="font-weight:bold">{{ dataInfo.order_amount | formatMoney }}</span>
    </el-col>
    <el-col :span="8" class="divs">
      折扣金额：
      <span style="font-weight:bold">{{ 0 | formatMoney }}</span>
    </el-col>
    <el-col :span="8" class="divs">
      订单总额：
      <span style="font-weight:bold;color:#f74848">{{ dataInfo.order_amount | formatMoney }}</span>
    </el-col>
    <div v-if="showStatus" class="showStatus">
      <fw-statusText :model="renderColor(dataInfo.order_status)" />
    </div>
  </el-row>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {
    dataInfo: {
      type: Object,
      default: null,
    },
    showStatus: {
      type: Boolean,
      default: false,
    },
  },
  created() {},
  mounted() {},
  data() {
    return {};
  },
  watch: {},
  computed: {
    renderColor() {
      return status => {
        let model = {
          text: "",
          color: "",
        };
        if (status == 10) {
          model = {
            text: "待收单",
            color: "#e6a23c",
          };
        } else if (status == 20) {
          model = {
            text: "待付款",
            color: "#e6a23c",
          };
        } else if (status == 30) {
          model = {
            text: "已付款",
            color: "#67c23a",
          };
        } else if (status == 40) {
          model = {
            text: "待发货",
            color: "#e6a23c",
          };
        } else if (status == 50) {
          model = {
            text: "待收货",
            color: "#e6a23c",
          };
        } else if (status == 60) {
          model = {
            text: "已收货",
            color: "#67c23a",
          };
        } else if (status == 70) {
          model = {
            text: "已取消",
            color: "#ccc",
          };
        } else if (status == 80) {
          model = {
            text: "已关闭",
            color: "#f56c6c",
          };
        } else if (status == 90) {
          model = {
            text: "已删除",
            color: "#ccc",
          };
        }
        return model;
      };
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.basmsg {
  position: relative;
  background-color: #fafafa;
  padding: 15px 25px;
  box-sizing: border-box;
  margin-bottom: 15px;
  .el-col {
    margin-bottom: 10px;
  }
  .divs {
    min-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .showStatus {
    position: absolute;
    right: 25px;
    top: 25px;
  }
}
.colors2 {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
}
</style>
