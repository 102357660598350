/* 侧边弹窗配置 */
<template>
  <el-drawer :visible.sync="drawer" direction="rtl" :with-header="false" size="40%">
    <div class="DrowJianyan" v-if="drawer">
      <fw-header titles="查看附件" :back="false" />
      <div style="margin-left:32px;min-height:85vh">
        <fw-uploadmore :models="models" :download="true" :looks="true" />
      </div>
      <el-row style="margin-top: 20px">
        <el-col :span="24" align="center">
          <el-button @click="no" style="width: 100px" size="small">取消</el-button>
        </el-col>
      </el-row>
    </div>
  </el-drawer>
</template>

<script>
export default {
  components: {},
  name: "DrowJianyan",
  data() {
    return {
      drawer: false,
      saveBack: null,
      models: {
        view: false, // 编辑
        onlyOne: false, // 是否只有一个文件
        max_width: "300px", // 宽度
        status: "download", // 状态  download下载  delete删除 （两个互斥）
        list: [], // 传递过来的文件列表
        looks: true,
      },
    };
  },
  methods: {
    open(val, back) {
      if (val.row) {
        let data = JSON.parse(JSON.stringify(val.row.receipt_image));
        this.models.list = data;
      }
      if (val.details) {
        this.models.view = false;
      }
      this.saveBack = back;
      this.drawer = true;
    },

    no() {
      this.drawer = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.DrowJianyan {
  min-height: 85vh;
  padding: 0 15px;
}
.height100 {
  height: 85vh;
}
</style>
