<template>
  <el-drawer
    :wrapperClosable="false"
    :destroy-on-close="true"
    :with-header="false"
    :visible.sync="drawer"
    direction="rtl"
    size="80%"
  >
    <div class="stockpoint">
      <el-container>
        <el-header height="auto">
          <fw-header titles="选择库位" :headers="{ back: false }" />
        </el-header>
        <el-main class="twoElmen">
          <div>
            <el-input style="margin-bottom: 10px" v-model="name"></el-input>
            <div
              v-for="item in data.filter(item => {
                return name ? item.name.indexOf(name) > -1 : true;
              })"
              :key="item.id"
              style="margin-bottom: 5px"
            >
              <div class="headers">
                {{ item.belong_company_name || "--" }}/{{ item.belong_company_code || "--" }}
              </div>
              <template v-for="it in item.storage">
                <div :key="it.id" v-if="type == 'checked'" style="line-height:32px">
                  <el-checkbox @change="storage_guide" v-model="it.checked">
                    {{ it.name }}
                  </el-checkbox>
                </div>
                <div :key="it.id" v-else style="line-height:32px">
                  <el-radio @change="storage_guide" v-model="radio" :label="it.id">
                    {{ it.name }}
                  </el-radio>
                </div>
              </template>
            </div>
          </div>
          <div class="contentsd">
            <div
              v-for="item in allTable"
              :key="item.id"
              :style="`width:${260 + item.seat * 100}px;margin-bottom:15px`"
            >
              <p style="margin-bottom: 8px">
                <span>{{ item.name }}</span>
              </p>
              <vxe-table
                border
                ref="xTable"
                class="mytable-scrollbar"
                :data="item.location"
                auto-resize
                show-overflow
              >
                <vxe-column
                  align="center"
                  type="seq"
                  fixed="left"
                  title="序号"
                  :width="60"
                ></vxe-column>
                <vxe-column field="area_name" fixed="left" :width="100">
                  <template #header><p>库区</p></template>
                  <template #default="{ row }">
                    <div
                      :title="row.area && row.area.name"
                      class="defaluts ellipsisText"
                      @click="chsoeKuqu(row, item)"
                    >
                      {{ (row.area && row.area.name) || "--" }}
                    </div>
                  </template>
                </vxe-column>
                <vxe-column field="line_floor_name" title="库行库层" fixed="left" :width="100">
                  <template #default="{ row }">
                    <div class="defaluts" @click="choseLine(row)">
                      {{ row.line }}行{{ row.floor }}层
                    </div>
                  </template>
                </vxe-column>

                <vxe-column
                  v-for="(its, int) in item.seat"
                  :key="its"
                  :title="(its < 10 ? '0' + its : its) + '库位'"
                  fixed="left"
                  :width="100"
                >
                  <template #default="{ row }">
                    <div
                      @click="choseThis(row, int, item)"
                      :class="['defaluts', 'hovers', showColor(row, int)]"
                      :style="`${showClass(row, int)}`"
                    >
                      {{ (row.location && row.location[int].location_code) || "--" }}
                    </div>
                  </template>
                </vxe-column>
              </vxe-table>
            </div>
          </div>
        </el-main>
        <el-footer>
          <el-row style="margin-top: 10px">
            <el-col :span="24" align="center">
              <el-button style="width: 88px" @click="drawer = false" size="small">取消</el-button>
              <el-button style="width: 88px" @click="confirm" size="small" type="primary">
                确认
              </el-button>
            </el-col>
          </el-row>
        </el-footer>
      </el-container>
    </div>
  </el-drawer>
</template>

<script>
/*	
		
			type:'radio', //选择类型 radio, checkbox
			showBack:[{storage_id:1,location:['010203']}], //回显 选中库位
		
*/
export default {
  data() {
    return {
      defaultSearch: {},
      radio: 0,
      radioType: 0,
      radioMsg: null,
      callback: null,
      type: "radio",
      drawer: false,
      name: "",
      data: [],
      goods: [],
      showBack: [],
      allTable: [],
      tableList: [], // 库区
      area: [], // 库位
      out_res_local_id: null, //下架库位id
    };
  },

  methods: {
    confirm() {
      if (this.type == "radio") {
        if (this.radioMsg) {
          this.drawer = false;
          this.callback(this.radioMsg);
        } else {
          this.$message.error("请先选择数据");
          return;
        }
      } else {
        let array = [];
        this.allTable.forEach(item => {
          let data = [];
          item.location.forEach(its => {
            its.location.forEach(ir => {
              if (ir.isChose) {
                data.push(ir);
              }
            });
          });
          array.push({
            id: item.id,
            name: item.name,
            serial_number: item.serial_number,
            //排班需要
            department_name: item.department_name, //管理部门
            type: item.type, //仓库类型
            warden: item.warden, //仓库负责人
            address: {
              //地址
              province_name: item.province_name,
              city_name: item.city_name,
              area_name: item.area_name,
              address: item.address,
            },
            data,
          });
        });
        this.drawer = false;
        this.callback(array);
      }
    },
    chsoeKuqu(row, item) {
      if (this.type == "radio") {
        return;
      }
      const id = row?.area?.id || 0;

      item.location.forEach(its => {
        if (its?.area?.id == id) {
          console.log("1231232313112");
          this.choseLine(its, true);
        }
      });
    },
    choseLine(row, val) {
      console.log(row);
      if (this.type == "radio") {
        return;
      }

      if (val) {
        row?.location.forEach(item => {
          if (item?.is_enable == 1) {
            this.$set(item, "isChose", true);
          }
        });
      } else {
        let msg = true;
        row?.location.forEach(item => {
          if (item?.is_enable == 1) {
            if (!item.isChose) {
              msg = false;
            }
          }
        });
        row?.location.forEach(item => {
          if (item?.is_enable == 1) {
            this.$set(item, "isChose", !msg);
          }
        });
      }
    },
    showColor(row, int) {
      if (row?.location[int]?.is_enable != 1) {
        return "none";
      } else {
        if (row?.location[int]?.is_have_goods > 0) {
          return "yellow";
        } else {
          return "green";
        }
      }
    },
    choseThis(row, int, item) {
      if (row?.location[int]?.is_enable != 1) {
        this.$message.error("当前库位已停用");
        return;
      }
      if (this.type == "radio") {
        console.log(row, "row");
        console.log(int, "int");
        console.log(item, "item");
        if (row?.location[int]?.isChose == true) {
          this.radioMsg = null;
          this.radioType = "";
          row.location[int].isChose = false;
          console.log(row, "row");
          return;
        } else {
          this.resetColor();
        }
        if (row?.location[int]?.radioType == this.radioType) {
          this.radioMsg = null;
          this.radioType = "";
          return;
        }
        this.radioType = Math.random()
          .toString(36)
          .slice(2);
        console.log(row);
        this.radioMsg = {
          id: item.id,
          name: item.name,
          serial_number: item.serial_number,
          data: row?.location[int],
        };
        if (row?.location[int]?.radioType) {
          row.location[int].radioType = this.radioType;
        } else {
          this.$set(row.location[int], "radioType", this.radioType);
        }
        console.log(this.radioMsg, "this.radioMsg");
      } else {
        if (row?.location[int]?.isChose) {
          row.location[int].isChose = false;
        } else {
          this.$set(row.location[int], "isChose", true);
        }
      }
    },
    showClass(row, int) {
      if (row?.location[int]?.isChose || row?.location[int]?.radioType == this.radioType) {
        let msg = "background-color: rgba(67, 135, 248, 1);color:#fff";
        return msg;
      } else {
        return "";
      }
    },
    rgb() {
      //rgb颜色随机
      const r = Math.floor(Math.random() * 256);
      const g = Math.floor(Math.random() * 256);
      const b = Math.floor(Math.random() * 256);
      const color = `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
      return color;
    },
    async open(val, callback) {
      this.callback = callback;
      console.log(val);
      if (val.type) {
        this.type = val.type;
      }
      if (val.showBack) {
        this.showBack = val.showBack;
      }

      if (val.out_res_local_id) {
        this.out_res_local_id = val.out_res_local_id;
      }
      if (val.defaultSearch) {
        this.defaultSearch = val.defaultSearch;
      }
      this.drawer = true;
      let goods = await this.$axios.postJSON("/scm/public/storage/storage/popup", {
        modular: "sc_goods_storage",
        ...this.defaultSearch,
      });
      this.data = goods.data.map((item, index) => {
        item.checked = false;
        if (this.showBack.length != 0) {
          // 回显
          this.showBack.forEach(i => {
            item.storage.forEach(it => {
              if (it.id == i.storage_id) {
                it.checked = true;
                this.radio = it.id;
              }
            });
          });
        } else {
          //初始
          if (index == 0) {
            item.storage.forEach(it => {
              if (!this.radio) {
                it.checked = true;
                this.radio = it.id;
              }
            });
          } else {
            item.checked = false;
          }
        }
        return item;
      });
      console.log(this.data);
      console.log(this.radio);
      this.storage_guide();
    },
    storage_guide() {
      let storage_id = [];
      if (this.type == "radio") {
        storage_id.push(this.radio);
      } else {
        this.data.forEach(item => {
          item.storage.forEach(it => {
            if (it.checked) {
              storage_id.push(it.id);
            }
          });
        });
      }
      this.$axios.postJSON("/scm/public/storage/storage/location", { id: storage_id }).then(res => {
        this.allTable = [];
        res.data.list.forEach(i => {
          i.location.forEach(k => {
            k.location.forEach(n => {
              n.location_id = n.id; //排班需要库位id
              n.id = k.area.id; //策略用来区分
              n.name = k.area.name; //策略用拿库区名字
              n.area = {
                id: k.area.id,
              };
              // n.locationCodeArr = []
              // n.isChose = true
              this.showBack.forEach(showItem => {
                if (showItem.storage_id == i.id) {
                  showItem.location.forEach(showLocationItem => {
                    if (showLocationItem == n.location_code) {
                      n.isChose = true;
                      this.radioMsg = {
                        id: showItem.storage_id,
                        name: i.name,
                        serial_number: i.serial_number,
                        data: n,
                      };
                    }
                  });
                }
              });
            });
          });
        });
        this.$nextTick(() => {
          this.allTable = res.data.list.map(item => {
            return item;
          });
          console.log(this.allTable);
        });
      });
    },
    resetColor() {
      this.allTable.forEach(i => {
        i.location.forEach(k => {
          k.location.forEach(n => {
            n.isChose = false;
          });
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.stockpoint {
  height: 100vh;
  margin: 0 20px;
  .twoElmen {
    display: flex;
    & > div:nth-child(1) {
      height: 100%;
      width: 260px;
      border: 1px solid #ccc;
      overflow-y: auto;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 10px;
    }
    & > div:nth-child(2) {
      margin-left: 10px;
      flex: 1;
      overflow: hidden;
      padding: 10px;
      box-sizing: border-box;
      height: 100%;
      overflow-y: auto;
    }
  }
  .showColor {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    & > div {
      margin-right: 10px;
      span {
        display: inline-block;
        margin-right: 5px;
        width: 12px;
        height: 12px;
        border-radius: 3px;
      }
    }
  }
}
.defaluts {
  height: 48px;
  line-height: 48px;
  margin: 0 -10px;
  text-indent: 10px;
  cursor: pointer;
  user-select: none;
}

.hovers {
  &:hover {
    background-color: rgba(67, 135, 248, 0.1);
    color: rgba(67, 135, 248, 1);
  }
}

.yellow {
  color: goldenrod;
}
.green {
  color: #20b704;
}
.none {
  color: #ccc;
}
.headers {
  background-color: #f5f5f5;
  padding: 6px 8px;
  margin: 0 -10px;
}
</style>
