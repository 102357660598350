<template>
  <div>
    <fw-title titles="收货明细" style="padding-top: 25px" />
    <el-row v-if="type == 1">
      <el-col :span="24" align="left">
        <!-- <span :title="'库位'" @click="morekuwei" class="el-icon-reading smallBTN"></span> -->
      </el-col>
    </el-row>
    <div style="margin:15px 0">
      <vxe-table
        style="margin-top: 10px;min-height:10vh"
        highlight-hover-row
        auto-resize
        stripe
        size="small"
        class="mytable-scrollbar"
        align="left"
        ref="xTable"
        :data="tableList"
      >
        <vxe-column type="checkbox" width="40" fixed="left"></vxe-column>
        <vxe-column type="seq" title="序号" width="50" fixed="left"></vxe-column>
        <!-- 物品名称 -->
        <!-- <vxe-column min-width="160" fixed="left">
          <template #header>
            <p>物品编号-小号</p>
            <p>物品名称</p>
            <p>物品单位</p>
            <p>物品描述</p>
          </template>
          <template #default="{ row }">
            <p
              class="ellipsisText"
              :title="
                `${
                  row.order_com_sku && row.order_com_sku.goods_serial_number
                 || '--'} - ${(row.order_com_sku && row.order_com_sku.goods_small_number) || ''}`
              "
            >
              {{
                `${
                  row.order_com_sku && row.order_com_sku.goods_serial_number
                 || "--"} - ${(row.order_com_sku && row.order_com_sku.goods_small_number) || ""}`
              }}
            </p>
            <p class="ellipsisText" :title="row.order_com_sku && row.order_com_sku.goods_name">
              {{ (row.order_com_sku && row.order_com_sku.goods_name) || "--" }}
            </p>
            <p class="ellipsisText" :title="row.order_com_sku && row.order_com_sku.goods_unit_name">
              {{ (row.order_com_sku && row.order_com_sku.goods_unit_name) || "--" }}
            </p>
            <p class="ellipsisText" :title="row.order_com_sku && row.order_com_sku.goods_desc">
              {{ (row.order_com_sku && row.order_com_sku.goods_desc) || "--" }}
            </p>
          </template>
        </vxe-column> -->
        <vxe-column min-width="160" fixed="left">
          <template #header>
            <p>供应商料号-小号</p>
            <p>商品名称</p>
            <p>商品单位</p>
            <p>商品描述</p>
          </template>
          <template #default="{ row }">
            <p
              class="ellipsisText"
              :title="
                `${row.order_com_sku.com_sku.serial_number ||
                  '--'} - ${(row.order_com_sku.com_sku.com_sku_goods &&
                  row.order_com_sku.com_sku.com_sku_goods.goods_small) ||
                  ''}`
              "
            >
              {{
                `${row.order_com_sku.com_sku.serial_number ||
                  "--"} - ${(row.order_com_sku.com_sku.com_sku_goods &&
                  row.order_com_sku.com_sku.com_sku_goods.goods_small) ||
                  ""}`
              }}
            </p>
            <p class="ellipsisText" :title="row.order_com_sku.com_sku.name">
              {{ row.order_com_sku.com_sku.name || "--" }}
            </p>
            <p
              class="ellipsisText"
              :title="
                row.order_com_sku.com_sku.com_sku_goods &&
                  row.order_com_sku.com_sku.com_sku_goods.goods_unit_name
              "
            >
              {{
                (row.order_com_sku.com_sku.com_sku_goods &&
                  row.order_com_sku.com_sku.com_sku_goods.goods_unit_name) ||
                  "--"
              }}
            </p>
            <p class="ellipsisText" :title="row.order_com_sku.com_sku.info">
              {{ row.order_com_sku.com_sku.info || "--" }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="160">
          <template #header>
            <p>供给方统一社会信用代码</p>
            <p>供给方名称</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText" :title="row.order_com_sku.seller_company_code">
              {{ row.order_com_sku.seller_company_code || "--" }}
            </p>
            <p class="ellipsisText" :title="row.order_com_sku.seller_company_name">
              {{ row.order_com_sku.seller_company_name || "--" }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="160">
          <template #header>
            <p>收货类型</p>
            <p>来源单号</p>
            <p>行号</p>
          </template>
          <template #default="{ row,$rowIndex }">
            <p>
              商城订单
            </p>
            <p class="ellipsisText" :title="row.serial_number">
              {{ row.serial_number || "--" }}
            </p>
            <p class="ellipsisText">
              {{ $rowIndex + 1 }}
            </p>
          </template>
        </vxe-column>
        <!-- 订单数量 -->
        <vxe-column min-width="160">
          <template #header>
            <p>订单数量</p>
            <p>订单单位</p>
            <p>订单金额</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText" :title="row.order_com_sku.buy_count">
              {{ row.order_com_sku.buy_count || "--" }}
            </p>
            <p class="ellipsisText" :title="row.order_com_sku.com_sku_valuation_unit">
              {{ row.order_com_sku.com_sku_valuation_unit || "--" }}
            </p>
            <p class="ellipsisText" style="color:#F74848">
              {{
                row.order_com_sku.protocol_sku_subtotal == "0.00"
                  ? row.order_com_sku.com_sku_subtotal
                  : row.order_com_sku.protocol_sku_subtotal | formatMoney
              }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="160">
          <template #header>
            <p>发货方</p>
            <p>发货时间</p>
            <p>物流单号</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText" :title="row.send_name">
              {{ row.send_name || "--" }}
            </p>
            <p class="ellipsisText" :title="row.send_at">
              {{ row.send_at || "--" }}
            </p>
            <p class="ellipsisText" :title="row.logistics_serial_number">
              {{ row.logistics_serial_number || "--" }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="160">
          <template #header>
            <p>来源-仓储批</p>
            <p>来源-装箱号</p>
            <p>来源-包装标识</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText" :title="row.storage_batch_no">
              {{ row.storage_batch_no || "--" }}
            </p>
            <p class="ellipsisText" :title="row.box_code">
              {{ row.box_code || "--" }}
            </p>
            <p class="ellipsisText" :title="row.pack_code">
              {{ row.pack_code || "--" }}
            </p>
          </template>
        </vxe-column>
        <!-- 来源-数量 -->
        <vxe-column min-width="160">
          <template #header>
            <p>来源-数量</p>
            <p>来源-单位</p>
            <p>发货金额</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText" :title="row.quantity">
              {{ row.quantity || 0 || "--" }}
            </p>
            <p class="ellipsisText" :title="row.unit_name">
              {{ row.unit_name || "--" }}
            </p>
            <h4 class="ellipsisText" style="color:#F74848" :title="row.amount">
              {{ row.amount | formatMoney }}
            </h4>
          </template>
        </vxe-column>
        <vxe-column min-width="160">
          <template #header>
            <!-- <p>来源-项目</p> -->
            <p>来源-批次号</p>
            <p>来源-序列号</p>
            <p>来源-有效期</p>
          </template>
          <template #default="{ row }">
            <!-- <p class="ellipsisText" :title="row.project_name">
              {{ row.project_name || "--" }}
            </p> -->
            <p class="ellipsisText" :title="row.goods_batch_no">
              {{ row.goods_batch_no || "--" }}
            </p>
            <p class="ellipsisText" :title="row.goods_sn">
              {{ row.goods_sn || "--" }}
            </p>
            <p class="ellipsisText" :title="row.goods_expire">
              {{ row.goods_expire || "--" }}
            </p>
          </template>
        </vxe-column>

        <!-- 收货-装箱号 -->
        <!-- <vxe-column min-width="160">
          <template #header>
            <p>收货-仓储批</p>
            <p>收货-装箱号</p>
            <p>收货-包装标识</p>
          </template>
          <template #default="{ row }">
            <template v-if="row.is_input">
              <div style="margin-bottom:10px">
                <el-input
                  placeholder="请输入"
                  size="small"
                  v-model="row.receipt_storage_batch_no"
                ></el-input>
              </div>
              <div style="margin-bottom:10px">
                <el-input
                  placeholder="请输入"
                  size="small"
                  v-model="row.receipt_box_code"
                ></el-input>
              </div>
              <div style="margin-bottom:10px">
                <el-input
                  placeholder="请输入"
                  size="small"
                  v-model="row.receipt_pack_code"
                ></el-input>
              </div>
            </template>
            <template v-else>
              <p class="ellipsisText" :title="row.receipt_storage_batch_no">
                {{ row.receipt_storage_batch_no || "--" }}
              </p>
              <p class="ellipsisText" :title="row.receipt_box_code">
                {{ row.receipt_box_code || "--" }}
              </p>
              <p class="ellipsisText" :title="row.receipt_pack_code">
                {{ row.receipt_pack_code || "--" }}
              </p>
            </template>
          </template>
        </vxe-column> -->
        <vxe-column min-width="160">
          <template #header>
            <p>收货-数量</p>
            <p>收货-单位</p>
            <p>收货金额</p>
          </template>
          <template #default="{ row }">
            <template v-if="row.is_input">
              <div style="margin-bottom:10px">
                <el-input-number
                  v-model="row.receipt_quantity"
                  controls-position="right"
                  :precision="3"
                  :step="0.001"
                  :min="0"
                  :max="Number(row.quantity)"
                  style="width: 140px"
                ></el-input-number>
              </div>
              <p class="ellipsisText" :title="row.receipt_unit_name">
                {{ row.receipt_unit_name || "--" }}
              </p>
              <h4 class="ellipsisText" style="color:#F74848">
                {{ subtotal(row) | formatMoney }}
              </h4>
            </template>
            <template v-else>
              <p class="ellipsisText" :title="row.receipt_quantity">
                {{ row.receipt_quantity || "--" }}
              </p>
              <p class="ellipsisText" :title="row.receipt_unit_name">
                {{ row.receipt_unit_name || "--" }}
              </p>
              <h4 class="ellipsisText" style="color:#F74848">
                {{ subtotal(row) | formatMoney }}
              </h4>
            </template>
          </template>
        </vxe-column>
        <!-- <vxe-column min-width="160">
          <template #header>
            <p>收货-项目</p>
            <p>收货-批次号</p>
            <p>收货-序列号</p>
            <p>收货-有效期</p>
          </template>
          <template #default="{ row }">
            <template v-if="row.is_input">
              <div style="margin-bottom:10px">
                <el-input
                  placeholder="请输入"
                  size="small"
                  v-model="row.receipt_project_name"
                ></el-input>
              </div>
              <div style="margin-bottom:10px">
                <el-input
                  placeholder="请输入"
                  size="small"
                  v-model="row.receipt_goods_batch_no"
                ></el-input>
              </div>
              <div style="margin-bottom:10px">
                <el-input
                  placeholder="请输入"
                  size="small"
                  v-model="row.receipt_goods_sn"
                ></el-input>
              </div>
              <div style="margin-bottom:10px">
                <el-date-picker
                  v-model="row.receipt_goods_expire"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                  style="width: 140px"
                ></el-date-picker>
              </div>
            </template>
            <template v-else>
              <p class="ellipsisText" :title="row.receipt_project_name">
                {{ row.receipt_project_name || "--" }}
              </p>
              <p class="ellipsisText" :title="row.receipt_goods_batch_no">
                {{ row.receipt_goods_batch_no || "--" }}
              </p>
              <p class="ellipsisText" :title="row.receipt_goods_sn">
                {{ row.receipt_goods_sn || "--" }}
              </p>
              <p class="ellipsisText" :title="row.receipt_goods_expire">
                {{ row.receipt_goods_expire || "--" }}
              </p>
            </template>
          </template>
        </vxe-column> -->
        <!-- 仓库编号 -->
        <!-- <vxe-column min-width="160">
          <template #header>
            <p>仓库编号</p>
            <p>仓库名称</p>
            <p>库位</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText" :title="row.storage_serial_number">
              {{ row.storage_serial_number || "--" }}
            </p>
            <p class="ellipsisText" :title="row.storage_name">
              {{ row.storage_name || "--" }}
            </p>
            <p>{{ row.location_code || "--" }}</p>
          </template>
        </vxe-column> -->
        <vxe-column fixed="right" min-width="100">
          <template #header>
            <p>操作</p>
          </template>
          <template #default="{ row }">
            <template v-if="type == 1">
              <p class="color" @click="upFile(row)">上传附件</p>
              <!-- <p class="color" @click="kuwei(row)">收货库位</p> -->
              <template v-if="row.is_input">
                <span class="color" style="margin-right:10px" @click="confirm(row)">确认</span>
                <span class="color" @click="cancel(row)">取消</span>
              </template>
              <template v-else>
                <p class="color" @click="editBtn(row)">收货</p>
              </template>
            </template>
            <template v-else>
              <p class="color" @click="lookFile(row)">查看附件</p>
            </template>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <!-- 仓库 -->
    <choseStock ref="choseStock" />
    <!--附件弹窗  -->
    <fileDialog ref="fileDialog" />
    <!-- 查看附件 -->
    <lookfile ref="lookfile" />
  </div>
</template>

<script>
import choseStock from "./choseStock.vue";
import fileDialog from "./fileDialog.vue";
import lookfile from "./lookfile.vue";
export default {
  name: "",
  components: {
    choseStock,
    fileDialog,
    lookfile
  },
  props: {
    tableList: {
      type: Array,
      default: [],
    },
    type: {
      type: Number,
      default: 1,
    },
  },
  created() {},
  mounted() {},
  data() {
    return {};
  },
  watch: {},
  computed: {
    subtotal() {
      return row => {
        const { order_com_sku, receipt_quantity } = row;
        let price =
          order_com_sku.protocol_sku_price == "0.00"
            ? order_com_sku.com_sku_price
            : order_com_sku.protocol_sku_price;
        let sum = Number(receipt_quantity || 0) * Number(price);
        row.receive_amount = sum;
        return sum;
      };
    },
  },
  methods: {
    morekuwei() {
      const checkedData = this.$refs.xTable.getCheckboxRecords();
      if (!checkedData.length) {
        return this.$message.error("请先勾选数据");
      }
      const obj = {
        type: "radio",
        showBack: [],
        out_res_local_id: 0,
      };
      this.$refs.choseStock.open(obj, val => {
        console.log(val);
        checkedData.forEach(row => {
          row.storage_id = val.id;
          row.storage_name = val.name;
          row.storage_serial_number = val.serial_number;
          row.location_code = val.data.location_code;
        });
      });
    },
    kuwei(row) {
      const obj = {
        type: "radio",
        showBack: row.storage_id
          ? [
              {
                storage_id: row.storage_id,
                location: [row.location_code],
              },
            ]
          : [],
        out_res_local_id: 0,
      };
      this.$refs.choseStock.open(obj, val => {
        console.log(val);
        row.storage_id = val.id;
        row.storage_name = val.name;
        row.storage_serial_number = val.serial_number;
        row.location_code = val.data.location_code;
      });
    },
    confirm(row) {
      let {
        // receipt_storage_batch_no,
        // receipt_box_code,
        // receipt_pack_code,
        receipt_quantity,
        // receipt_project_name,
        // receipt_goods_batch_no,
        // receipt_goods_sn,
        // receipt_goods_expire,
        quantity,
      } = row;
      // if (!receipt_storage_batch_no) {
      //   return this.$message.error("请输入收货-仓储批");
      // }
      // if (!receipt_box_code) {
      //   return this.$message.error("请输入收货-装箱号");
      // }
      // if (!receipt_pack_code) {
      //   return this.$message.error("请输入收货-包装标识");
      // }
      if (!receipt_quantity) {
        return this.$message.error("请输入收货-数量");
      }
      // if (!receipt_project_name) {
      //   return this.$message.error("请输入收货-项目");
      // }
      // if (!receipt_goods_batch_no) {
      //   return this.$message.error("请输入收货-批次号");
      // }
      // if (!receipt_goods_sn) {
      //   return this.$message.error("请输入收货-序列号");
      // }
      // if (!receipt_goods_expire) {
      //   return this.$message.error("请输入收货-有效期");
      // }
      if (receipt_quantity != quantity) {
        return this.$message.error("收货数量与发货数量不一致,请重新选择");
      }
      row.is_input = false;
      row.isAdd = false;
    },
    cancel(row) {
      if (row.isAdd) return;
      let {
        // receipt_storage_batch_no,
        // receipt_box_code,
        // receipt_pack_code,
        receipt_quantity,
        // receipt_project_name,
        // receipt_goods_batch_no,
        // receipt_goods_sn,
        // receipt_goods_expire,
      } = row.saveData;
      // row.receipt_storage_batch_no = receipt_storage_batch_no;
      // row.receipt_box_code = receipt_box_code;
      // row.receipt_pack_code = receipt_pack_code;
      row.receipt_quantity = receipt_quantity;
      // row.receipt_project_name = receipt_project_name;
      // row.receipt_goods_batch_no = receipt_goods_batch_no;
      // row.receipt_goods_sn = receipt_goods_sn;
      // row.receipt_goods_expire = receipt_goods_expire;
      row.is_input = false;
    },
    editBtn(row) {
      let {
        // receipt_storage_batch_no,
        // receipt_box_code,
        // receipt_pack_code,
        receipt_quantity,
        // receipt_project_name,
        // receipt_goods_batch_no,
        // receipt_goods_sn,
        // receipt_goods_expire,
      } = row;
      row.saveData = {
        // receipt_storage_batch_no,
        // receipt_box_code,
        // receipt_pack_code,
        receipt_quantity,
        // receipt_project_name,
        // receipt_goods_batch_no,
        // receipt_goods_sn,
        // receipt_goods_expire,
      };
      row.is_input = true;
    },
    upFile(row) {
      this.$refs.fileDialog.open({ row }, val => {
        console.log(val);
        row.receipt_image = val;
      });
    },
    lookFile(row) {
      this.$refs.lookfile.open({ row }, val => {
        console.log(val);
        row.receipt_image = val;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.smallBTN {
  display: inline-block;
  width: 18px;
  height: 18px;
  line-height: 18px;
  color: #fff;
  text-align: center;
  background-color: #4387f8;
  margin-right: 5px;
  border-radius: 3px;
  cursor: pointer;
}
.color {
  color: #4387f8;
  cursor: pointer;
}
</style>
