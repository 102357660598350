var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-drawer',{attrs:{"wrapperClosable":false,"destroy-on-close":true,"with-header":false,"visible":_vm.drawer,"direction":"rtl","size":"80%"},on:{"update:visible":function($event){_vm.drawer=$event}}},[_c('div',{staticClass:"stockpoint"},[_c('el-container',[_c('el-header',{attrs:{"height":"auto"}},[_c('fw-header',{attrs:{"titles":"选择库位","headers":{ back: false }}})],1),_c('el-main',{staticClass:"twoElmen"},[_c('div',[_c('el-input',{staticStyle:{"margin-bottom":"10px"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_vm._l((_vm.data.filter(function (item) {
              return _vm.name ? item.name.indexOf(_vm.name) > -1 : true;
            })),function(item){return _c('div',{key:item.id,staticStyle:{"margin-bottom":"5px"}},[_c('div',{staticClass:"headers"},[_vm._v(" "+_vm._s(item.belong_company_name || "--")+"/"+_vm._s(item.belong_company_code || "--")+" ")]),_vm._l((item.storage),function(it){return [(_vm.type == 'checked')?_c('div',{key:it.id,staticStyle:{"line-height":"32px"}},[_c('el-checkbox',{on:{"change":_vm.storage_guide},model:{value:(it.checked),callback:function ($$v) {_vm.$set(it, "checked", $$v)},expression:"it.checked"}},[_vm._v(" "+_vm._s(it.name)+" ")])],1):_c('div',{key:it.id,staticStyle:{"line-height":"32px"}},[_c('el-radio',{attrs:{"label":it.id},on:{"change":_vm.storage_guide},model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}},[_vm._v(" "+_vm._s(it.name)+" ")])],1)]})],2)})],2),_c('div',{staticClass:"contentsd"},_vm._l((_vm.allTable),function(item){return _c('div',{key:item.id,style:(("width:" + (260 + item.seat * 100) + "px;margin-bottom:15px"))},[_c('p',{staticStyle:{"margin-bottom":"8px"}},[_c('span',[_vm._v(_vm._s(item.name))])]),_c('vxe-table',{ref:"xTable",refInFor:true,staticClass:"mytable-scrollbar",attrs:{"border":"","data":item.location,"auto-resize":"","show-overflow":""}},[_c('vxe-column',{attrs:{"align":"center","type":"seq","fixed":"left","title":"序号","width":60}}),_c('vxe-column',{attrs:{"field":"area_name","fixed":"left","width":100},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("库区")])]},proxy:true},{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('div',{staticClass:"defaluts ellipsisText",attrs:{"title":row.area && row.area.name},on:{"click":function($event){return _vm.chsoeKuqu(row, item)}}},[_vm._v(" "+_vm._s((row.area && row.area.name) || "--")+" ")])]}}],null,true)}),_c('vxe-column',{attrs:{"field":"line_floor_name","title":"库行库层","fixed":"left","width":100},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('div',{staticClass:"defaluts",on:{"click":function($event){return _vm.choseLine(row)}}},[_vm._v(" "+_vm._s(row.line)+"行"+_vm._s(row.floor)+"层 ")])]}}],null,true)}),_vm._l((item.seat),function(its,int){return _c('vxe-column',{key:its,attrs:{"title":(its < 10 ? '0' + its : its) + '库位',"fixed":"left","width":100},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('div',{class:['defaluts', 'hovers', _vm.showColor(row, int)],style:(("" + (_vm.showClass(row, int)))),on:{"click":function($event){return _vm.choseThis(row, int, item)}}},[_vm._v(" "+_vm._s((row.location && row.location[int].location_code) || "--")+" ")])]}}],null,true)})})],2)],1)}),0)]),_c('el-footer',[_c('el-row',{staticStyle:{"margin-top":"10px"}},[_c('el-col',{attrs:{"span":24,"align":"center"}},[_c('el-button',{staticStyle:{"width":"88px"},attrs:{"size":"small"},on:{"click":function($event){_vm.drawer = false}}},[_vm._v("取消")]),_c('el-button',{staticStyle:{"width":"88px"},attrs:{"size":"small","type":"primary"},on:{"click":_vm.confirm}},[_vm._v(" 确认 ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }