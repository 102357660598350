var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{staticStyle:{"padding":"0px 15px"}},[(_vm.is_edit)?_c('el-col',{staticClass:"right_btn",attrs:{"span":24}},[_c('el-button',{staticStyle:{"margin-right":"20px","width":"88px"},attrs:{"size":"small","type":"primary","plain":""},on:{"click":_vm.addNew}},[_vm._v(" 新增 ")])],1):_vm._e(),_c('el-col',{staticStyle:{"margin-top":"15px"},attrs:{"span":24}},[_c('vxe-table',{ref:"xTable1",staticClass:"mytable-scrollbar",attrs:{"max-height":_vm.$store.state.clientHeight - 220 + 'px',"stripe":"","highlight-hover-row":"","size":"small","border":false,"id":"toolbar_demo3","data":_vm.listData,"row-key":"","auto-resize":""}},[_c('vxe-column',{attrs:{"type":"seq","title":"序号","width":"60"}}),_c('vxe-column',{attrs:{"title":"设计箱号","field":"box_code","min-width":"140"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[(_vm.is_edit)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e(),_vm._v(" 设计箱号 ")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [(row.is_input)?[_c('el-input',{attrs:{"placeholder":"请输入","size":"small"},model:{value:(row.box_code),callback:function ($$v) {_vm.$set(row, "box_code", $$v)},expression:"row.box_code"}})]:[_vm._v(" "+_vm._s(row.box_code || "--")+" ")]]}}])}),_c('vxe-column',{attrs:{"title":"长(m)","field":"length","min-width":"140"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[(_vm.is_edit)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e(),_vm._v(" 长(m) ")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [(row.is_input)?[_c('el-input-number',{attrs:{"placeholder":"请输入","controls-position":"right","min":0,"precision":2},model:{value:(row.length),callback:function ($$v) {_vm.$set(row, "length", $$v)},expression:"row.length"}})]:[_vm._v(" "+_vm._s(row.length || "--")+" ")]]}}])}),_c('vxe-column',{attrs:{"title":"宽(m)","field":"width","min-width":"140"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[(_vm.is_edit)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e(),_vm._v(" 宽(m) ")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [(row.is_input)?[_c('el-input-number',{attrs:{"placeholder":"请输入","controls-position":"right","min":0,"precision":2},model:{value:(row.width),callback:function ($$v) {_vm.$set(row, "width", $$v)},expression:"row.width"}})]:[_vm._v(" "+_vm._s(row.width || "--")+" ")]]}}])}),_c('vxe-column',{attrs:{"title":"高(m)","field":"height","min-width":"140"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[(_vm.is_edit)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e(),_vm._v(" 高(m) ")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [(row.is_input)?[_c('el-input-number',{attrs:{"placeholder":"请输入","controls-position":"right","min":0,"precision":2},model:{value:(row.height),callback:function ($$v) {_vm.$set(row, "height", $$v)},expression:"row.height"}})]:[_vm._v(" "+_vm._s(row.height || "--")+" ")]]}}])}),_c('vxe-column',{attrs:{"title":"重量(kg)","field":"weight","min-width":"140"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[(_vm.is_edit)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e(),_vm._v(" 重量(kg) ")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [(row.is_input)?[_c('el-input-number',{attrs:{"placeholder":"请输入","controls-position":"right","min":1,"precision":2},model:{value:(row.weight),callback:function ($$v) {_vm.$set(row, "weight", $$v)},expression:"row.weight"}})]:[_vm._v(" "+_vm._s(row.weight || "--")+" ")]]}}])}),_c('vxe-column',{attrs:{"title":"箱内零件序号","field":"num","min-width":"140"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[(_vm.is_edit)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e(),_vm._v(" 箱内零件序号 ")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [(row.is_input)?[_c('el-input',{attrs:{"placeholder":"请输入","size":"small"},model:{value:(row.num),callback:function ($$v) {_vm.$set(row, "num", $$v)},expression:"row.num"}})]:[_vm._v(" "+_vm._s(row.num || "--")+" ")]]}}])}),(_vm.is_edit)?_c('vxe-column',{attrs:{"title":"操作","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var rowIndex = ref.rowIndex;
return [(row.is_input)?_c('div',{staticClass:"act-btn-box"},[_c('el-link',{staticClass:"act-btn",attrs:{"underline":false,"type":"primary"},on:{"click":function($event){return _vm.confirmBtn(row)}}},[_vm._v(" 确定 ")]),_c('el-link',{staticClass:"act-btn",attrs:{"underline":false,"type":"primary"},on:{"click":function($event){return _vm.cancelBtn(row, rowIndex)}}},[_vm._v(" 取消 ")])],1):_c('div',{staticClass:"act-btn-box"},[_c('el-link',{staticClass:"act-btn",attrs:{"underline":false,"type":"primary"},on:{"click":function($event){return _vm.editBtn(row)}}},[_vm._v(" 编辑 ")]),_c('el-link',{staticClass:"act-btn",attrs:{"underline":false,"type":"primary"},on:{"click":function($event){return _vm.removeBtn(row, rowIndex)}}},[_vm._v(" 删除 ")])],1)]}}],null,false,1698109746)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }