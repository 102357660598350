var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('fw-title',{staticStyle:{"padding-top":"25px"},attrs:{"titles":"发货商品"}}),_c('vxe-table',{ref:"xTable1",staticClass:"mytable-scrollbar",staticStyle:{"min-height":"10vh"},attrs:{"stripe":"","highlight-hover-row":"","size":"small","id":"toolbar_demo3","row-config":{ height: 120 },"data":_vm.tableList,"custom-config":{
      storage: true,
    },"expand-config":{
      expandAll: true,
      showIcon: false,
      iconOpen: 'el-icon-remove', // 展
      iconClose: 'el-icon-circle-plus', // 收
    }}},[_c('vxe-column',{attrs:{"type":"expand","title":"序号","width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var rowIndex = ref.rowIndex;
return [_c('span',[_vm._v(_vm._s(rowIndex + 1))])]}},{key:"content",fn:function(spacoe){return [_c('div',{staticClass:"expand-wrapper"},[_c('vxe-table',{staticClass:"mytable-scrollbar",attrs:{"auto-resize":"","stripe":"","highlight-hover-row":"","size":"small","border":false,"data":spacoe.row.send_info}},[_c('vxe-column',{attrs:{"width":"80px"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("序号")])]},proxy:true},{key:"default",fn:function(ref){
    var rowIndex = ref.rowIndex;
return [_c('p',[_vm._v(_vm._s(rowIndex + 1))])]}}],null,true)}),_c('vxe-column',{attrs:{"min-width":"180"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("仓库批")])]},proxy:true},{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.storage_batch_no}},[_vm._v(" "+_vm._s(row.storage_batch_no || "--")+" ")])]}}],null,true)}),_c('vxe-column',{attrs:{"min-width":"180"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("批次号")])]},proxy:true},{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.batch_number}},[_vm._v(" "+_vm._s(row.batch_number || "--")+" ")])]}}],null,true)}),_c('vxe-column',{attrs:{"min-width":"180"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("序列号")])]},proxy:true},{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.serial_number}},[_vm._v(" "+_vm._s(row.serial_number || "--")+" ")])]}}],null,true)}),_c('vxe-column',{attrs:{"min-width":"180"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("有效期")])]},proxy:true},{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.validity_start + '-' + row.validity_end}},[_vm._v(" "+_vm._s(row.validity_start || "--")+" - "+_vm._s(row.validity_end || "--")+" ")])]}}],null,true)}),_c('vxe-column',{attrs:{"min-width":"180"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("库存数量")])]},proxy:true},{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.num}},[_vm._v(" "+_vm._s(row.num || "--")+" ")])]}}],null,true)}),_c('vxe-column',{attrs:{"min-width":"180"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("发货数量")])]},proxy:true},{key:"default",fn:function(ref){
    var row = ref.row;
return [[_c('el-input-number',{staticStyle:{"width":"140px"},attrs:{"controls-position":"right","precision":3,"step":0.001,"min":0,"max":Number(row.num),"disabled":!row.is_input},model:{value:(row.quantity),callback:function ($$v) {_vm.$set(row, "quantity", $$v)},expression:"row.quantity"}})]]}}],null,true)}),_c('vxe-column',{attrs:{"title":"操作","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [(row.is_input)?[_c('el-link',{staticClass:"links",attrs:{"type":"primary","underline":false},on:{"click":function($event){return _vm.comfirmBtn(row)}}},[_vm._v(" 确认 ")])]:[_c('el-link',{staticClass:"links",attrs:{"type":"primary","underline":false},on:{"click":function($event){return _vm.editBtn(row)}}},[_vm._v(" 发货数量 ")])]]}}],null,true)})],1)],1)]}}])}),_c('vxe-column',{attrs:{"min-width":"130","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("图片")])]},proxy:true},{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('el-image',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":row.com_sku.cover_img_path,"fit":"cover","preview-src-list":[row.com_sku.cover_img_path]}})]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("商品信息")])]},proxy:true},{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.com_sku.name}},[_vm._v(" "+_vm._s(row.com_sku.name || "--")+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":("" + (row.com_sku.serial_number) + (row.com_sku.serial_number ? '-' + row.com_sku.goods_small_sort_num : ''))}},[_vm._v(" "+_vm._s(("" + (row.com_sku.serial_number) + (row.com_sku.serial_number ? "-" + row.com_sku.goods_small_sort_num : "")))+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.com_sku.info}},[_vm._v(" "+_vm._s(row.com_sku.info || "--")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("商品规格")])]},proxy:true},{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":_vm.specJson(row.com_sku.spec_json)}},[_vm._v(" "+_vm._s(_vm.specJson(row.com_sku.spec_json) || "--")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("单位")])]},proxy:true},{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.com_sku.unit_name}},[_vm._v(" "+_vm._s(row.com_sku.unit_name || "--")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("平台SPU")]),_c('p',[_vm._v("平台SKU")])]},proxy:true},{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.com_sku.sku_number}},[_vm._v(" "+_vm._s(row.com_sku.sku_number || "--")+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.com_sku.spu_number}},[_vm._v(" "+_vm._s(row.com_sku.spu_number || "--")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("销售单价")])]},proxy:true},{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.com_sku.price}},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(row.com_sku.price))+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("订购数量")]),_c('p',[_vm._v("金额小计")])]},proxy:true},{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.buy_count}},[_vm._v(" "+_vm._s(row.buy_count || "--")+" ")]),_c('p',{staticStyle:{"color":"#f74848"}},[_vm._v(_vm._s(_vm._f("formatMoney")(row.com_sku_subtotal)))])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("发货数量")]),_c('p',[_vm._v("发货金额")])]},proxy:true},{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('p',[_vm._v(_vm._s(_vm.numtotal(row)))]),_c('p',{staticStyle:{"color":"#f74848"}},[_vm._v(_vm._s(_vm._f("formatMoney")(_vm.pricetotal(row))))])]}}])}),_c('vxe-column',{attrs:{"title":"操作","width":"100","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('el-link',{staticClass:"links",attrs:{"type":"primary","underline":false},on:{"click":function($event){return _vm.storageBtn(row, 1)}}},[_vm._v(" 商城库存 ")]),_c('el-link',{staticClass:"links",attrs:{"type":"primary","underline":false},on:{"click":function($event){return _vm.storageBtn(row, 2)}}},[_vm._v(" 工厂库存 ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }