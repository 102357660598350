<template>
  <div class="wra">
    <el-drawer
      :visible.sync="visible"
      :wrapperClosable="false"
      :with-header="false"
      direction="rtl"
      size="80%"
      id="addSucai"
      :destroy-on-close="true"
    >
      <el-container style="height: 100%">
        <el-header height="auto">
          <fw-header titles="收货详情" />
        </el-header>
        <el-main v-if="dataInfo">
          <basmsg :dataInfo="dataInfo" />
          <div class="main_bot">
            <div>
              <span style="margin-right:45px;color:#858585;">配送至</span>
              <span style="margin-right:15px">
                {{ (dataInfo.receipt_info && dataInfo.receipt_info.name) || "--" }}
              </span>
              <span style="margin-right:15px">
                {{ dataInfo.receipt_info && dataInfo.receipt_info.mobile }}
              </span>
              <span style="margin-right:15px">
                {{ dataInfo.receipt_info && dataInfo.receipt_info.province_name }}&nbsp;{{
                  dataInfo.receipt_info && dataInfo.receipt_info.city_name
                }}&nbsp;{{ dataInfo.receipt_info && dataInfo.receipt_info.area_name }}&nbsp;{{
                  dataInfo.receipt_info && dataInfo.receipt_info.address
                }}
              </span>
            </div>
            <div>
              发运单号:
              <span style="color:#333;margin-left:15px">
                {{ dataInfo.shipping.serial_number || "--" }}
              </span>
            </div>
          </div>

          <div>
            <receipt-goods-table
              :tableList="dataInfo.shipping.goods"
              :type="2"
              ref="receiptGoodsTable"
            />
          </div>

          <div>
            <fw-title titles="收货附件" style="padding-top: 25px" />
            <el-row class="packzone">
              <el-col :span="2" style="color:#606266">
                <span class="konggefu"></span>
                图片
              </el-col>
              <el-col :span="22">
                <div
                  style="padding: 0px 15px;display:flex"
                  v-if="receipt_image && receipt_image.length > 0"
                >
                  <fwuploadphoto
                    @postImgMsg="postImgMsg"
                    :backShow="receipt_image"
                    :mustDelet="true"
                    :onlyLook="true"
                  />
                </div>
                <div v-else>
                  /
                </div>
              </el-col>
            </el-row>
          </div>
        </el-main>
        <el-footer>
          <el-row>
            <el-col :span="24" align="center">
              <el-button size="small" @click="close">取消</el-button>
            </el-col>
          </el-row>
        </el-footer>
      </el-container>
      <fw-inoutsup ref="fwInoutsup" />
      <fw-chosePeople ref="fw_chosePeople" />
    </el-drawer>
  </div>
</template>

<script>
import Basmsg from "./basmsg.vue";
import ReceiptGoodsTable from "./receiptGoodsTable.vue";
export default {
  name: "",
  components: {
    Basmsg,
    ReceiptGoodsTable,
  },
  props: {},
  created() {},
  mounted() {},
  data() {
    return {
      visible: false,
      callFn: null, //回调
      order_id: null,
      rowId: null,
      dataInfo: null,
      formLabelAlign: {},
      rules: {},
      receipt_image: [], //收货附件
    };
  },
  watch: {},
  computed: {},
  methods: {
    open(data, callFn) {
      console.log(data);
      this.callFn = callFn;
      this.order_id = data.id;
      if (data && data.rowId) {
        this.rowId = data.rowId;
      }
      this.getDefault();
      this.getBasicInfo();
      this.visible = true;
    },
    getDefault() {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      console.log(userInfo);
      this.receipt_image = [];
    },
    getBasicInfo() {
      let params = {
        id: Number(this.order_id),
        order_shipping_id: Number(this.rowId),
      };
      this.$axios.get(`/shop/public/common/personal/receipt_detail`, params).then(res => {
        if (res.data) {
          console.log(res.data);
          this.dataInfo = res.data;
          if (res.data.shipping) {
            this.dataInfo.shipping.goods = res.data.shipping.goods.map(v => {
              v["serial_number"] = res.data.serial_number;
              v["send_name"] = res.data.shipping.send_by;
              v["send_at"] = res.data.shipping.send_at;
              v["logistics_serial_number"] = res.data.shipping.logistics_serial_number;
              return v;
            });
          }

          this.receipt_image = res.data.shipping.receipt_image;
        }
      });
    },

    postImgMsg(val) {
      console.log(val);
    },

    close() {
      this.visible = false;
      this.dataInfo = null;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-drawer__body {
  padding-left: 25px;
  padding-right: 25px;
}
.main_bot {
  background-color: #fafafa;
  margin-top: 25px;
  line-height: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 25px;
  div {
    display: flex;
    align-items: center;
  }
}
.packzone {
  padding: 10px 15px;
  .el-col {
    margin-bottom: 15px;
  }
}
.choseGongji {
  margin-top: 12px;
  position: relative;
  display: inline-block;
  width: 400px;
  margin-right: 3%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fafafa;
  & > p {
    color: #0088fe;
    line-height: 30px !important;
  }
  p {
    line-height: 24px;
  }
  & > i {
    position: absolute;
    top: -5px;
    right: -5px;
    color: #ccc;
    cursor: pointer;
  }

  & > div {
    display: flex;
    & > div:nth-child(1) {
      width: 100px;
    }
    & > div:nth-child(2) {
      overflow: hidden;
      flex: 1;
      p {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.btn {
  width: 400px;
  border-color: #409eff;
  color: #409eff;
  &:hover {
    background-color: #c0defc;
    font-weight: bolder;
  }
}
.logistics_bar {
  display: flex;
  align-items: center;
}
</style>
