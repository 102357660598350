<template>
  <div>
    <fw-title titles="发货商品" style="padding-top: 25px" />

    <vxe-table
      style="min-height:10vh"
      class="mytable-scrollbar"
      stripe
      highlight-hover-row
      size="small"
      id="toolbar_demo3"
      :row-config="{ height: 120 }"
      ref="xTable1"
      :data="tableList"
      :custom-config="{
        storage: true,
      }"
      :expand-config="{
        expandAll: true,
        showIcon: false,
        iconOpen: 'el-icon-remove', // 展
        iconClose: 'el-icon-circle-plus', // 收
      }"
    >
      <vxe-column type="expand" title="序号" width="60">
        <template #default="{ rowIndex }">
          <span>{{ rowIndex + 1 }}</span>
        </template>
        <template #content="spacoe">
          <div class="expand-wrapper">
            <vxe-table
              auto-resize
              class="mytable-scrollbar"
              stripe
              highlight-hover-row
              size="small"
              :border="false"
              :data="spacoe.row.send_info"
            >
              <vxe-column width="80px">
                <template #header>
                  <p>序号</p>
                </template>
                <template #default="{ rowIndex }">
                  <p>{{ rowIndex + 1 }}</p>
                </template>
              </vxe-column>
              <vxe-column min-width="180">
                <template #header>
                  <p>仓库批</p>
                </template>
                <template #default="{ row }">
                  <p class="ellipsisText" :title="row.storage_batch_no">
                    {{ row.storage_batch_no || "--" }}
                  </p>
                </template>
              </vxe-column>
              <vxe-column min-width="180">
                <template #header>
                  <p>批次号</p>
                </template>
                <template #default="{ row }">
                  <p class="ellipsisText" :title="row.batch_number">
                    {{ row.batch_number || "--" }}
                  </p>
                </template>
              </vxe-column>
              <vxe-column min-width="180">
                <template #header>
                  <p>序列号</p>
                </template>
                <template #default="{ row }">
                  <p class="ellipsisText" :title="row.serial_number">
                    {{ row.serial_number || "--" }}
                  </p>
                </template>
              </vxe-column>
              <vxe-column min-width="180">
                <template #header>
                  <p>有效期</p>
                </template>
                <template #default="{ row }">
                  <p class="ellipsisText" :title="row.validity_start + '-' + row.validity_end">
                    {{ row.validity_start || "--" }} - {{ row.validity_end || "--" }}
                  </p>
                </template>
              </vxe-column>
              <vxe-column min-width="180">
                <template #header>
                  <p>库存数量</p>
                </template>
                <template #default="{ row }">
                  <p class="ellipsisText" :title="row.num">
                    {{ row.num || "--" }}
                  </p>
                </template>
              </vxe-column>
              <vxe-column min-width="180">
                <template #header>
                  <p>发货数量</p>
                </template>
                <template #default="{ row }">
                  <template>
                    <el-input-number
                      v-model="row.quantity"
                      controls-position="right"
                      :precision="3"
                      :step="0.001"
                      :min="0"
                      :max="Number(row.num)"
                      :disabled="!row.is_input"
                      style="width: 140px"
                    ></el-input-number>
                  </template>
                </template>
              </vxe-column>
              <vxe-column title="操作" width="100">
                <template #default="{row}">
                  <template v-if="row.is_input">
                    <el-link
                      class="links"
                      type="primary"
                      :underline="false"
                      @click="comfirmBtn(row)"
                    >
                      确认
                    </el-link>
                  </template>
                  <template v-else>
                    <el-link class="links" type="primary" :underline="false" @click="editBtn(row)">
                      发货数量
                    </el-link>
                  </template>
                </template>
              </vxe-column>
            </vxe-table>
          </div>
        </template>
      </vxe-column>
      <vxe-column min-width="130" align="center">
        <template #header>
          <p>图片</p>
        </template>
        <template #default="{ row }">
          <el-image
            style="width: 100px; height: 100px"
            :src="row.com_sku.cover_img_path"
            fit="cover"
            :preview-src-list="[row.com_sku.cover_img_path]"
          ></el-image>
        </template>
      </vxe-column>
      <vxe-column min-width="160">
        <template #header>
          <p>商品信息</p>
        </template>
        <template #default="{ row }">
          <p class="ellipsisText" :title="row.com_sku.name">
            {{ row.com_sku.name || "--" }}
          </p>
          <p
            class="ellipsisText"
            :title="
              `${row.com_sku.serial_number}${
                row.com_sku.serial_number ? '-' + row.com_sku.goods_small_sort_num : ''
              }`
            "
          >
            {{
              `${row.com_sku.serial_number}${
                row.com_sku.serial_number ? "-" + row.com_sku.goods_small_sort_num : ""
              }`
            }}
          </p>
          <p class="ellipsisText" :title="row.com_sku.info">
            {{ row.com_sku.info || "--" }}
          </p>
        </template>
      </vxe-column>
      <vxe-column min-width="160">
        <template #header>
          <p>商品规格</p>
        </template>
        <template #default="{ row }">
          <p class="ellipsisText" :title="specJson(row.com_sku.spec_json)">
            {{ specJson(row.com_sku.spec_json) || "--" }}
          </p>
        </template>
      </vxe-column>
      <vxe-column min-width="160">
        <template #header>
          <p>单位</p>
        </template>
        <template #default="{ row }">
          <p class="ellipsisText" :title="row.com_sku.unit_name">
            {{ row.com_sku.unit_name || "--" }}
          </p>
        </template>
      </vxe-column>
      <vxe-column min-width="160">
        <template #header>
          <p>平台SPU</p>
          <p>平台SKU</p>
        </template>
        <template #default="{ row }">
          <p class="ellipsisText" :title="row.com_sku.sku_number">
            {{ row.com_sku.sku_number || "--" }}
          </p>
          <p class="ellipsisText" :title="row.com_sku.spu_number">
            {{ row.com_sku.spu_number || "--" }}
          </p>
        </template>
      </vxe-column>
      <vxe-column min-width="160">
        <template #header>
          <p>销售单价</p>
        </template>
        <template #default="{ row }">
          <p class="ellipsisText" :title="row.com_sku.price">
            {{ row.com_sku.price | formatMoney }}
          </p>
        </template>
      </vxe-column>
      <vxe-column min-width="160">
        <template #header>
          <p>订购数量</p>
          <p>金额小计</p>
        </template>
        <template #default="{ row }">
          <p class="ellipsisText" :title="row.buy_count">
            {{ row.buy_count || "--" }}
          </p>
          <p style="color: #f74848">{{ row.com_sku_subtotal | formatMoney }}</p>
        </template>
      </vxe-column>
      <vxe-column min-width="160">
        <template #header>
          <p>发货数量</p>
          <p>发货金额</p>
        </template>
        <template #default="{ row }">
          <p>{{ numtotal(row) }}</p>
          <p style="color: #f74848">{{ pricetotal(row) | formatMoney }}</p>
        </template>
      </vxe-column>
      <vxe-column title="操作" width="100" fixed="right">
        <template #default="{ row }">
          <el-link class="links" type="primary" :underline="false" @click="storageBtn(row, 1)">
            商城库存
          </el-link>
          <el-link class="links" type="primary" :underline="false" @click="storageBtn(row, 2)">
            工厂库存
          </el-link>
        </template>
      </vxe-column>
    </vxe-table>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {
    tableList: {
      type: Array,
      default: [],
    },
  },
  created() {},
  mounted() {},
  data() {
    return {};
  },
  watch: {},
  computed: {
    specJson() {
      return list => {
        if (!list || !list.length) return "--";
        return (
          list
            ?.map(item => {
              let msg = item.spec_name + "  " + item.spec_value_name + item.spec_unit_name;
              return msg;
            })
            ?.join("、") ?? "--"
        );
      };
    },

    numtotal() {
      return row => {
        const { send_info } = row;
        let sum = 0;
        sum = send_info.reduce((total, item) => total + item.quantity, 0);
        row.num = sum;
        return sum;
      };
    },
    pricetotal() {
      return row => {
        const { send_info, com_sku_price, protocol_sku_price } = row;
        let sum = 0;
        sum = send_info.reduce((total, item) => total + item.quantity, 0);
        let price = 0;
        if (Number(protocol_sku_price) == 0) {
          price = sum * Number(com_sku_price);
        } else {
          price = sum * Number(protocol_sku_price);
        }
        row.amount = price;
        return price;
      };
    },
  },
  methods: {
    query(row) {
      console.log(row);
    },
    storageBtn(row, num) {
      if (num == 2) return;
      //暂无功能
      else {
        console.log(row);
        let com_sku_id = row.com_sku_id;

        let params = {
          com_sku_id: [com_sku_id],
          page_size: 1000,
        };
        this.$axios.postJSON("/shop/cust/com_center/inventory/stock_list", params).then(res => {
          if (res.code == 200) {
            console.log(res);
            let data = res.data.data.map(v => {
              let obj = {
                inventory_id: v.id, //商城库存id 商城库存传
                storage_batch_no: v.storage_batch_no, //仓库批
                batch_number: v.batch_number, //批次号
                serial_number: v.serial_number, //序列号
                validity_start: v.validity_start, //有效期开始时间
                validity_end: v.validity_end, //有效期结束时间
                num: v.num, //库存数量
                quantity: "", //发货数量
                is_input: true,
              };
              return obj;
            });
            row.send_info = [...data];
          }
        });
      }
    },
    comfirmBtn(row) {
      row.is_input = false;
    },
    editBtn(row) {
      row.is_input = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.expand-wrapper {
  padding: 20px;
  background-color: #ecf3fe;
}
</style>
