/* 取消订单弹窗 */
<template>
  <el-dialog
    :width="'550px'"
    :visible.sync="visible"
    :title="'取消订单'"
    :close-on-click-modal="false"
    :show-close="false"
    top="30vh"
  >
    <el-row :class="'opinion-box'" :style="{ 'line-height': '50px' }">
      <el-col :span="6">
        <span class="remark">*</span>
        取消说明
      </el-col>
      <el-col :span="17" :offset="1">
        <div>
          <el-input
            :autosize="{ minRows: 5, maxRows: 7 }"
            resize="none"
            placeholder="请输入"
            style="width: 90%"
            type="textarea"
            v-model="content"
            maxlength="100"
            show-word-limit
          ></el-input>
        </div>
        <div>
          <i class="el-icon-warning" style="color:#f8ba43;fontSize:18px"></i> 订单确认取消后不可恢复。
        </div>
      </el-col>
    </el-row>
    <div class="footer-box">
      <el-button size="small" class="footer-btn" @click="visible = false">取消</el-button>
      <el-button size="small" type="primary" class="footer-btn" @click="confirmBtn">
        确定
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      visible: false,
      callback: null,
      content: null,
    };
  },
  created() {},
  mounted() {},
  methods: {
    open(val, callback) {
      console.log(val);
      this.content = null;
      this.callback = callback;
      this.visible = true;
    },
    confirmBtn() {
      let content = this.content;

      console.log(content, "this.content");
      if (content == null) {
        return this.$message.error("请输入取消说明");
      }
      this.callback(content);
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.opinion-box {
  padding-left: 20px;
  height: 150px;
  line-height: 150px;
  .input-box {
    margin-left: 12px;
    width: 80%;
  }
}
.bar_box {
  display: flex;
  align-items: center;
}
/deep/.el-dialog__header {
  padding-bottom: 0px;
}
/deep/.el-dialog__body {
  padding-top: 10px;
}
.footer-box {
  margin-top: 15px;
  text-align: center;

  .footer-btn {
    width: 80px;
  }
}
.remark {
  color: #f74848;
}
</style>
