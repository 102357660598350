/* 发运打包 */
<template>
  <el-row style="padding:0px 15px">
    <el-col :span="24" class="right_btn" v-if="is_edit">
      <el-button
        @click="addNew"
        size="small"
        style="margin-right:20px;width: 88px"
        type="primary"
        plain
      >
        新增
      </el-button>
    </el-col>
    <el-col :span="24" style="margin-top:15px">
      <vxe-table
        :max-height="$store.state.clientHeight - 220 + 'px'"
        class="mytable-scrollbar"
        stripe
        highlight-hover-row
        size="small"
        :border="false"
        ref="xTable1"
        id="toolbar_demo3"
        :data="listData"
        row-key
        auto-resize
      >
        <vxe-column type="seq" title="序号" width="60"></vxe-column>
        <vxe-column title="设计箱号" field="box_code" min-width="140">
          <template #header>
            <p>
              <span style="color:red" v-if="is_edit">*</span>
              设计箱号
            </p>
          </template>
          <template #default="{row}">
            <template v-if="row.is_input">
              <el-input placeholder="请输入" size="small" v-model="row.box_code"></el-input>
            </template>
            <template v-else>
              {{ row.box_code || "--" }}
            </template>
          </template>
        </vxe-column>
        <vxe-column title="长(m)" field="length" min-width="140">
          <template #header>
            <p>
              <span style="color:red"  v-if="is_edit">*</span>
              长(m)
            </p>
          </template>
          <template #default="{row}">
            <template v-if="row.is_input">
              <el-input-number
                v-model="row.length"
                placeholder="请输入"
                controls-position="right"
                :min="0"
                :precision="2"
              ></el-input-number>
            </template>
            <template v-else>
              {{ row.length || "--" }}
            </template>
          </template>
        </vxe-column>
        <vxe-column title="宽(m)" field="width" min-width="140">
          <template #header>
            <p>
              <span style="color:red"  v-if="is_edit">*</span>
              宽(m)
            </p>
          </template>
          <template #default="{row}">
            <template v-if="row.is_input">
              <el-input-number
                v-model="row.width"
                placeholder="请输入"
                controls-position="right"
                :min="0"
                :precision="2"
              ></el-input-number>
            </template>
            <template v-else>
              {{ row.width || "--" }}
            </template>
          </template>
        </vxe-column>
        <vxe-column title="高(m)" field="height" min-width="140">
          <template #header>
            <p>
              <span style="color:red"  v-if="is_edit">*</span>
              高(m)
            </p>
          </template>
          <template #default="{row}">
            <template v-if="row.is_input">
              <el-input-number
                v-model="row.height"
                placeholder="请输入"
                controls-position="right"
                :min="0"
                :precision="2"
              ></el-input-number>
            </template>
            <template v-else>
              {{ row.height || "--" }}
            </template>
          </template>
        </vxe-column>
        <vxe-column title="重量(kg)" field="weight" min-width="140">
          <template #header>
            <p>
              <span style="color:red"  v-if="is_edit">*</span>
              重量(kg)
            </p>
          </template>
          <template #default="{row}">
            <template v-if="row.is_input">
              <el-input-number
                v-model="row.weight"
                placeholder="请输入"
                controls-position="right"
                :min="1"
                :precision="2"
              ></el-input-number>
            </template>
            <template v-else>
              {{ row.weight || "--" }}
            </template>
          </template>
        </vxe-column>
        <vxe-column title="箱内零件序号" field="num" min-width="140">
          <template #header>
            <p>
              <span style="color:red"  v-if="is_edit">*</span>
              箱内零件序号
            </p>
          </template>
          <template #default="{row}">
            <template v-if="row.is_input">
              <el-input placeholder="请输入" size="small" v-model="row.num"></el-input>
            </template>
            <template v-else>
              {{ row.num || "--" }}
            </template>
          </template>
        </vxe-column>
        <vxe-column title="操作" width="140" v-if="is_edit">
          <template #default="{row,rowIndex}">
            <div v-if="row.is_input" class="act-btn-box">
              <el-link :underline="false" class="act-btn" type="primary" @click="confirmBtn(row)">
                确定
              </el-link>
              <el-link
                :underline="false"
                class="act-btn"
                type="primary"
                @click="cancelBtn(row, rowIndex)"
              >
                取消
              </el-link>
            </div>
            <div v-else class="act-btn-box">
              <el-link :underline="false" class="act-btn" type="primary" @click="editBtn(row)">
                编辑
              </el-link>
              <el-link
                :underline="false"
                class="act-btn"
                type="primary"
                @click="removeBtn(row, rowIndex)"
              >
                删除
              </el-link>
            </div>
          </template>
        </vxe-column>
      </vxe-table>
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: "",
  props: {
    listData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    is_edit: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    addNew() {
      let last = this.listData[0] || {};
      if (last.isAdd) {
        return;
      }
      this.listData.splice(0, 0, {
        isAdd: true,
        is_input: true,
        box_code: null,
        length: null,
        width: null,
        height: null,
        weight: null,
        num: null,
        saveData: {},
      });
    },
    confirmBtn(row) {
      if (!row.length) {
        return this.$message.error("请输入长");
      }
      if (!row.width) {
        return this.$message.error("请输入宽");
      }
      if (!row.height) {
        return this.$message.error("请输入高");
      }
      if (!row.num) {
        return this.$message.error("请输入箱内零件序号");
      }
      if (!row.box_code) {
        return this.$message.error("请输入设计箱号");
      }

      let box_code = this.listData.map(item => item.box_code);
      let flagArr = new Set(box_code);
      console.log(flagArr);
      if (flagArr.size != this.listData.length) {
        return this.$message.error("设计箱号重复,请重新输入");
      }
      row.is_input = false;
      row.isAdd = false;
      // this.$emit("setPack", this.listData);
    },
    editBtn(row) {
      row.is_input = true;
      let { box_code, length, width, height, weight, num } = row;
      row.saveData = { box_code, length, width, height, weight, num };
    },
    cancelBtn(row, index) {
      if (row.isAdd) {
        this.listData.splice(index, 1);
      } else {
        row.is_input = false;
        let { box_code, length, width, height, weight, num } = row.saveData;
        row.box_code = box_code;
        row.length = length;
        row.width = width;
        row.height = height;
        row.weight = weight;
        row.num = num;
      }
    },
    removeBtn(row, index) {
      this.$confirm("是否确定删除该条数据？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let arr = JSON.parse(JSON.stringify(this.listData));
        arr.splice(index, 1);

        this.$emit("setPack", arr);
        this.$message.success("删除成功！");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.act-btn {
  margin-right: 5px;
  color: #66b1fc;
}
</style>
